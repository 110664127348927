import request from "@/sheep/request";

const HomeApi = {
  // 获取bannar 列表
  getBannerList(data) {
    return request({
      url: "promotion/banner/list",
      method: "GET",
      data,
    });
  },
  getBannerListWithoutToast(data) {
    return request({
      url: "promotion/banner/list",
      method: "GET",
      data,
      custom: {
        showLoading: false,
      },
    });
  },
  // 领取优惠券弹框
  getCouponLogin(data) {
    return request({
      url: "promotion/coupon/login-take",
      method: "GET",
      data,
    });
  },
  // 搜索附件地址信息
  getMemberAddressList(params) {
    return request({
      url: "member/address/search/page",
      method: "GET",
      params,
    });
  },
  // 是否拥有指定栏目权限根据卡券
  getContainType(params) {
    return request({
      url: "pay/recharge-equity/containType",
      method: "GET",
      params,
    });
  },
  getContainTypeWithoutToast(params) {
    return request({
      url: "pay/recharge-equity/containType",
      method: "GET",
      params,
      custom: {
        showLoading: false,
      },
    });
  },

  // 是否拥有指定栏目权限根据编码
  getContainTypeByCode(params) {
    return request({
      url: "pay/recharge-equity/containTypeCode",
      method: "GET",
      params,
    });
  },
  // 是否拥有指定栏目权限根据编码2
  getContainTypeByCode2(params) {
    return request({
      url: "pay/recharge-equity/containTypeCode2",
      method: "GET",
      params,
    });
  },
  
  // 是否显示电影频道点餐入库
  getCardDetail(params) {
    return request({
      url: "pay/coupons-user/isShowMovieDiancan",
      method: "GET",
      params,
    });
  },
  // 是否拥有指定栏目权限根据用户
  getUserType(params) {
    return request({
      url: "pay/recharge-equity/containType",
      method: "GET",
      params,
    });
  },

  // 根据栏目查商品列表
  getSpuList(data) {
    return request({
      url: "product/spu/page-by-type",
      method: "GET",
      data: data,
    });
  },
  getSpuListWithoutToast(data) {
    return request({
      url: "product/spu/page-by-type",
      method: "GET",
      data: data,
      custom: {
        showLoading: false,
      },
    });
  },
  // 根据专区获得商品 SPU 分页
  getSpuListByZone(data) {
    return request({
      url: "product/spu/page-by-activityId",
      method: "GET",
      data: data,
    });
  },
  getSpuListByZoneWithoutToast(data) {
    return request({
      url: "product/spu/page-by-activityId",
      method: "GET",
      data: data,
      custom: {
        showLoading: false,
      },
    });
  },
  // 根据栏目数组查商品列表
  getSpusList(data) {
    return request({
      url: "product/spu/page-by-type-list",
      method: "POST",
      data: data,
    });
  },
  // 获取首页信息
  getHomeMessage() {
    return request({
      url: "promotion/diy-page/get",
      method: "GET",
      data: {
        id: 6,
      },
    });
  },

  // 获取电影城市列表
  getCityList() {
    return request({
      url: "movie/city/list",
      method: "GET",
    });
  },
  // 获取电影城市列表
  getNewCityList(params) {
    return request({
      url: "system/area/listCity",
      method: "GET",
      params
    });
  },
  // 获取栏目
  getTypeList(data) {
    return request({
      url: "product/type/getTypeList",
      method: "GET",
      data,
    });
  },
  getTypeListWithoutToast(data) {
    return request({
      url: "product/type/getTypeList",
      method: "GET",
      data,
      custom: {
        showLoading: false,
      }
    });
  },
  //转发get 请求
  getProxyUrl(data) {
    return request({
      url: "system/proxy/get",
      method: "GET",
      data,
    });
  },

  //  根据用户绑定卡券获取栏目 /trade/homepage/typeList
  getActiveType(params) {
    return request({
      url: "trade/homepage/typeList",
      method: "GET",
      params
    });
  },

  // 根据用户绑定卡券获取生日汇是否先试试福乐兑
  getPayRedeem(params) {
    return request({
      url: "pay/coupons-user/redeem",
      params
    });
  },
  //  获取默认地址
  getMrAddress(params) {
    return request({
      url: "member/address/get-default",
      method: "GET",
      params
    })
  },
  getMrAddressWithoutToast(params) {
    return request({
      url: "member/address/get-default",
      method: "GET",
      params,
      custom: {
        showLoading: false,
      }
    })
  },
  // 根据用户绑定卡券获取生日汇是否先试试福乐兑
  getPayRedeem(params) {
    return request({
      url: "pay/coupons-user/redeem",
      method: "GET",
      params
    });
  },

  //  根据活动id  获取活动详情 
  businessAuthRedirect(params) {
    return request({
      url: "member/auth/business-auth-redirect",
      method: "GET",
      params
    });
  },
  //  根据活动id  获取活动详情 
  getActiveInfo(params) {
    return request({
      url: "pay/card-activity/get-activity",
      method: "GET",
      params
    });
  },
  //  根据活动id 获取用户是否还有可消费的卡
  getIsCardCanUse(params) {
    return request({
      url: "pay/coupons-user/isCardCanUseInActivity",
      method: "GET",
      params
    });
  },
  // 获得抽屉分类栏目排序
  getMoreType() {
    return request({
      url: "product/type-user/get-more-type",
      method: "GET",
    });
  },
  // 更新用户自定义导航
  upMoreType(data) {
    return request({
      url: "product/type-user/save-more-type",
      method: "PUT",
      data,
    });
  },

  // 获取活动详情-专区
  getActiveInfo2() {
    return request({
      url: "pay/card-activity/get-url-activity",
      method: "GET",
    })
  },

  // 获得抽屉分类栏目排序
  getMoreType() {
    return request({
      url: "product/type-user/get-more-type",
      method: "GET",
    });
  },
  // 更新用户自定义导航
  upMoreType(data) {
    return request({
      url: "product/type-user/save-more-type",
      method: "PUT",
      data,
    });
  },



  // 获取用户自定义的导航入口
  getCustomNav() {
    return request({
      url: "product/type-user/get",
      method: "GET",
    });
  },

  // 更新用户自定义导航
  updateCustomNav(data) {
    return request({
      url: "product/type-user/save",
      method: "PUT",
      data,
    });
  },



  // 获得活动的tap栏目
  getActivityTapList() {
    return request({
      url: "product/type/getTypeListByActivity",
      method: "GET",
    })
  },

  // 获得活动的一级栏目
  getActivityOneColumnList(params){
    return request({
      url: "product/type/getOneTypeListByActivity",
      method: "GET",
      params
    })
  },

  // 获取用户是否有该专区的卡
  getIsCardInActivity() {
    return request({
      url: "pay/coupons-user/isCardInActivity",
      method: "GET",
    })
  },

  // 获得当前秒杀活动
  getActivityNow(params) {
    return request({
      url: "promotion/seckill-activity/get-now",
      method: "GET",
      params
    });
  },
  // 获取秒杀时间段 
  getSeckillTime(params) {
    return request({
      url: "promotion/seckill-config/list",
      method: "GET",
      params
    });
  },

  // 获取秒杀分页 
  getSeckillList(params) {
    return request({
      url: "promotion/seckill-activity/page",
      method: "GET",
      params
    });
  },

  // 获取秒杀活动详情 
  getSeckillDetail(params) {
    return request({
      url: "promotion/seckill-activity/get-detail",
      method: "GET",
      params
    });
  },
  // 效验秒杀商品是否可以购买
  getSeckillCheck(params) {
    return request({
      url: "promotion/seckill-activity/check",
      method: "GET",
      params
    });
  },

  // 获取会员全部充值权益
  getRechargeAll(params) {
    return request({
      url: "pay/recharge-equity/get/all",
      method: "GET",
      params
    });
  },

  // 获取是否弹窗调查问卷 
  getUserPopup(params) {
    return request({
      url: "member/user/verify-user_popup",
      method: "POST",
      params
    });
  },

  // 根据优惠券Id 获取栏目
  getTypeByCard(params) {
    return request({
      url: "product/type/getTypeListByCouponId",
      method: "GET",
      params
    });
  },

  // 判断优惠券是否是本人  
  getCouponCard(params) {
    return request({
      url: "promotion/coupon/get",
      method: "GET",
      params
    });
  },

  // 判断优惠券是否是同一类型 
  getCouponCardType(params) {
    return request({
      url: "promotion/coupon/get-same",
      method: "GET",
      params
    });
  },
  


};

export default HomeApi;

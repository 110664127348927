// 是否开启访问模式
import { isWebView ,  callPoint} from "@/utils/common";


const baseWhitePage = [
    '/pages/author/transit',
    '/pages/author/index',
    '/MP_verify_TdX7yqmdNNoP1lK4.txt',
    '/pages/public/error',
    '/pages/YunfuHelpNew/home',
    '/pages/YunfuHelpNew/helpLibrary',
    '/pages/goods/index',
    '/pages/index/festivals',
    '/pages/policy/index',      

]

let whitePage = [
    ...baseWhitePage,
    '/pages/index/index',
    '/pages/index/user',
    '/pages/goods/list',
    '/pages/cakeModule/CakeHome',
    '/pages/YunfuHelp/home',
    '/pages/YunfuHelp/helpLibrary',
    '/pages/movie/index',
    '/pages/equityModule/equityHome',
    '/pages/index/discount',
    '/pages/index/promotion',

    // 斗鱼
    '/pages/index/promotion_dytb',
    '/pages/movie/cinema-list',
    '/pages/movie/choice-movie',
    '/pages/movie/movie-search',
    '/pages/equityModule/equityHome_dy',
    '/pages/equityModule/equitySearch',
    '/pages/movie/cinema-equity',
    '/pages/travel/index',
    '/pages/travel/poiList',
    '/pages/common/open-setting-location-home/open-setting-location-home',
    '/pages/travel/poiDetails',
    '/pages/tmall/index',
    '/pages/tmall/category',
    '/pages/tmall/search',
    '/pages/tmall/searchList',
    '/pages/JD/home',
    '/pages/JD/search',
    '/pages/JD/specialPage',
    '/pages/JD/brandGoodsPage',
    '/pages/JD/category',
    '/pages/ZHSC/index',
    '/pages/ZHSC/juhui',
    '/pages/ZHSC/search',
    '/pages/ZHSC/huigou',
    '/pages/ZHSC/baopin',
    '/pages/ZHSC/category',
    '/pages/goods/searchList',
    'pages/index/search',
    '/pages/cakeModule/CakeAllBrand',
    '/pages/cakeModule/CakeBrand',
    '/pages/index/promotionSearch',
]



export function GetWhitePages(val) {
  const isWeb = sessionStorage.getItem('isWeb') || ''

  const openVisitorMode = uni.getStorageSync('openVisitorMode')
  if(isWebView() || isWeb == 'isWeb') {
    return whitePage    
  }else{
    return val || openVisitorMode ? whitePage : baseWhitePage;
  }
}


export function IsWhitePage() {
    const openVisitorMode = uni.getStorageSync('openVisitorMode')
    const path = window.location.href
    let isWhitePage = false;
    if (!path.includes('/pages')) {
        // 首页
        if (openVisitorMode) isWhitePage = true;
        else {
            if (uni.getStorageSync('token')) isWhitePage = true;
            else isWhitePage = false;
        }
    }
    else {
        const arr = GetWhitePages();
        arr.map(item => {
            if (path.includes(item)) {
                isWhitePage = true;
            }
        })
    }

    return isWhitePage;
}